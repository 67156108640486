import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../../components/header"
import Section from "../../components/section"
import TwmButton from "../../components/twm-button"

import Img from "gatsby-image"

import { FlexWrapper, BoxWrapper } from "../../components/contentwrappers"

export default function Blog(props) {
  const { posts } = props.data.blog

  return (
    <div>
      <Layout firmName={props.pageContext.firm} firmWeb={props.pageContext.web}>
        <SEO title="Finding True Wealth" />
        <BackgroundImage
          fluid={props.data.newspaperImage.childImageSharp.fluid}
          alt="A newspaper"
        >
          <Header headerText="Finding True Wealth"></Header>
        </BackgroundImage>
        <Section type="wide">
          <p>
            Welcome to our blog, Finding True Wealth. Being "wealthy" means
            different things to different people. For some it can refer to their
            money and assets but for others it may mean financial freedom and
            having the means to fulfil life goals etc. Here we'll include
            articles to help you on your way to find your "True Wealth".
          </p>

          <p>
            As well as these articles, we produce guides from time-to-time.{" "}
            <Link to={props.pageContext.firm + "/guides"}>Click here</Link> to
            browse our previous guides.
          </p>

          <FlexWrapper blogs>
            {posts.map(post => (
              <BoxWrapper key={post.id}>
                <h3>{post.frontmatter.title}</h3>

                <small>{post.frontmatter.date}</small>
                <Img
                  fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
                />
                <p style={{ margin: "0 0 1em 0" }}>
                  {post.frontmatter.description}
                </p>

                <TwmButton
                  toRef={props.pageContext.firm + post.fields.slug}
                  type="alt"
                >
                  Read More
                </TwmButton>
              </BoxWrapper>
            ))}
          </FlexWrapper>
        </Section>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark(
      filter: { frontmatter: { type: { ne: "guide" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM YYYY")
          title
          description
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        excerpt
        id
      }
    }
    newspaperImage: file(relativePath: { eq: "newspaper.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
